<template>
  <div class="donate">
    <div class="content">

      <!-- 顶部 -->
      <Header :titleName="data.titleName" :return="true" :borderShow="true"></Header>
      <!-- 捐赠信息 -->
      <div class=" donate_msg">
        <div class=" items classname align_items_center" style="display: none;">
          <div style="display: block;">
            在线捐赠
            <div class="line"></div>
          </div>
        </div>
        <div class="items space_between align_items_center" v-if="!data.projId || data.projId == 16">
          <div>捐赠形式</div>
          <div class="btns">
            <div class="active">现金捐赠</div>
            <div @click="toIntegral">积分捐赠</div>
          </div>
        </div>
        <div class="items donate_sum">
          <div>捐赠金额<div class="bt">必填</div>
          </div>
          <div class="align_items_center">
            <div class="f40">￥</div>
            <van-field type="number" v-model="data.postData.amount" :input-align="'left'"
              placeholder="输入自定义金额或选择以下固定金额" />
          </div>
          <div class="space_between num_btns">
            <div :class="data.postData.amount == 5 ? 'active' : ''" @click="amountBtnChange(5)"><span
                class="f40 ">5</span>
              元</div>
            <div :class="data.postData.amount == 10 ? 'active' : ''" @click="amountBtnChange(10)"><span class="f40"
                @click="amountBtnChange(5)">10</span>元</div>
            <div :class="data.postData.amount == 20 ? 'active' : ''" @click="amountBtnChange(20)"><span class="f40"
                @click="amountBtnChange(10)">20</span>元</div>
            <div :class="data.postData.amount == 100 ? 'active' : ''" @click="amountBtnChange(100)"><span class="f40"
                @click="amountBtnChange(100)">100</span>元</div>
          </div>
        </div>
        <div class="items space_between align_items_center" v-if="!data.projId">
          <div>捐赠类别</div>
          <div class="btns">
            <div :class="data.postData.assignProject == 0 ? 'active' : ''" @click="assignProjectChange(0)">无定向捐款</div>
            <div :class="data.postData.assignProject == 1 ? 'active' : ''" @click="assignProjectChange(1)">指定项目捐款</div>
          </div>
        </div>

        <div class="items space_between align_items_center" v-if="data.postData.assignProject == 1 && !data.projId">
          <div>项目名称<div class="bt">必填</div>
          </div>
          <div @click="porjectDiaShow()">
            <span v-if="data.postData.oname" style="color: #333;">{{ data.postData.oname }}</span>
            <span v-else style="color: #ACACAC;">请选择项目</span>
            <img class="right" src="@/assets/images/donate/right.png" alt="">
          </div>
        </div>

        <div class="items space_between align_items_center" style="display: none;">
          <div>捐赠人信息</div>
          <div class="space_between align_items_center c8 f21">
            <img class="notice" src="@/assets/images/donate/notice.png" alt="">
            注：单位捐赠信息将在核实后公示
          </div>
        </div>
        <div class="items space_between align_items_center">
          <div>
            捐款来源
            <div class="bt">必填</div>
          </div>
          <div class="btns">
            <div :class="data.postData.type == 1 ? 'active' : ''" @click="typeChange(1)">个人</div>
            <div :class="data.postData.type == 2 ? 'active' : ''" @click="typeChange(2)">单位</div>
          </div>
        </div>

        <!-- 个人捐赠选项 -->
        <div v-if="data.postData.type == 1">
          <div class="items space_between align_items_center">
            <div style="">捐赠人姓名</div>
            <van-field v-model="data.postData.person" :input-align="'right'" placeholder="请输入捐赠人姓名" />

          </div>
          <div class="items space_between align_items_center">
            <div style="">所属单位名称</div>
            <van-field v-model="data.postData.briefl_name" :input-align="'right'" placeholder="可输入单位简称" />

          </div>
		  
          <div class="items space_between align_items_center">
            <div>捐赠人手机号</div>
            <van-field v-model="data.postData.phone" :input-align="'right'" placeholder="请输入捐赠人手机号" />
          </div>
		  
		  <div class="items space_between align_items_center">
		    <div>捐赠人邮箱</div>
		    <van-field v-model="data.postData.email" :input-align="'right'" placeholder="请输入捐赠人邮箱" />
		  </div>
        </div>

        <!-- 单位捐赠选项 -->
        <div v-else>
          <div class="items space_between align_items_center">
            <div style="">单位全称 <div class="bt">必填</div>
            </div>
            <van-field v-model="data.postData.briefl_name" :input-align="'right'" placeholder="请输入单位全称(企业对公捐款)" />

          </div>

          <div class="items space_between align_items_center">
            <div>手机号 <div class="bt">必填</div>
            </div>
            <van-field v-model="data.postData.phone" :input-align="'right'" placeholder="请输入联系人手机号" />
          </div>
		  
		  <div class="items space_between align_items_center">
		    <div style="">联系人 <div class="bt">必填</div>
		    </div>
		    <van-field v-model="data.postData.person" :input-align="'right'" placeholder="请输入联系人" />
		  
		  </div>
		  
          <div class="items space_between align_items_center">
            <div>邮箱</div>
            <van-field v-model="data.postData.email" :input-align="'right'" placeholder="请输入邮箱" />
          </div>
        </div>


        <!-- <div class="items space_between align_items_center">
          <div>渠道</div>
          <div>
            <span style="color: #ACACAC;">主要为保险业，采矿（后台配置）</span>
            <img class="right" src="@/assets/images/donate/right.png" alt="">
          </div>
        </div> -->

        <div class="items space_between align_items_center">
          <div>是否需要收据</div>
          <div class="btns">
            <div :class="data.postData.receipt == 0 ? 'active' : ''" @click="receiptChange(0)">不需要</div>
            <div :class="data.postData.receipt == 1 ? 'active' : ''" @click="receiptChange(1)">需要</div>
          </div>
        </div>

        <!-- 需要收据 -->
        <div v-if="data.postData.receipt == 1">
          <div class="items space_between align_items_center">
            <div>收据抬头 <div class="bt">必填</div>
            </div>
            <van-field v-model="data.postData.invoice_title" :input-align="'right'" placeholder="请输入收据抬头" />
          </div>
          <div class="items space_between align_items_center">
            <div>收据税号 <div class="bt" v-if="data.postData.type == 2">必填</div></div>
            <van-field v-model="data.postData.invoice_tax_id" :input-align="'right'" placeholder="请输入收据税号" />
          </div>
          <div class="items space_between align_items_center">
            <div>收据邮箱 <div class="bt">必填</div>
            </div>
            <van-field v-model="data.postData.invoice_email" :input-align="'right'" placeholder="请输入邮箱" />
          </div>
        </div>


        <div class="items space_between align_items_center">
          <div>捐赠信息列入公示</div>
          <div class="btns">
            <div :class="data.postData.openBel  ? 'active' : ''" @click="data.postData.openBel=true">允许</div>
            <div :class="!data.postData.openBel ? 'active' : ''" @click="data.postData.openBel=false">不允许</div>
          </div>
          

        </div>

        <div class="items space_between align_items_center">
          <div>支付方式</div>
          <div class="btns">
            <div :class="data.postData.payType == 1 ? 'active' : ''" @click="payTypeChange(1)">
              <img v-if="data.postData.payType == 1" src="@/assets/images/donate/wechat.png" alt="">
              <img v-else src="@/assets/images/donate/wechat_a.png" alt="">

              微信支付
            </div>
            <div :class="data.postData.payType == 2 ? 'active' : ''" @click="payTypeChange(2)">
              <img v-if="data.postData.payType == 2" src="@/assets/images/donate/alipay.png" alt="">
              <img v-else src="@/assets/images/donate/alipay_a.png" alt="">
              支付宝
            </div>
          </div>
        </div>
        <div class="space_between align_items_center">
          <div class="submitBtn" @click="submit">立即捐赠</div>
        </div>
      </div>
      <div style="height: .2667rem;background-color: #f5f5f5;"></div>

      <!-- 银行汇款信息 -->
      <div class="blank_msg">
        <div class=" items classname space_between align_items_center">
          <div style="display: block;">
            银行汇款
            <div class="line"></div>
          </div>
          <div class="justify_content_end align_items_center" style="font-size: .2933rem;color: #888;font-weight: 400;"
            @click="data.blankMsgShow = !data.blankMsgShow">
            {{ data.blankMsgShow ? '收起' : '查看详情' }}
            <img class="zk" src="@/assets/images/donate/zk.png" alt=""
              :style="data.blankMsgShow ? ' transform: rotateZ(180deg);' : ''">
          </div>
        </div>
        <div v-if="data.blankMsgShow">
          <div class="items space_between align_items_center">
            <div>户名</div>
            <div>中国职工发展基金会</div>
          </div>
          <div class="items space_between align_items_center">
            <div>账户</div>
            <div class="fw6"> 1105 0111 1570 0000 0020</div>
          </div>
          <div class="items space_between align_items_center" style="border: none;">
            <div>开户行</div>
            <div>中国建设银行股份有限公司北京广渠门支行</div>
          </div>
        </div>

      </div>




      <!-- 捐赠成功弹窗 -->
      <div class="jj_dialog succeed" v-if="data.succeedDiaShow">
        <div class="succeed_view">
          <img class="succeed_img" src="@/assets/images/donate/succeed.png" alt="">
          <div style="color: #888;margin-bottom: .4rem;">捐款成功</div>
          <div>
            <div class="btn red_btn" @click="toZSdeatil">查看证书</div>
            <div class="btn" @click="data.succeedDiaShow = false">返回</div>
          </div>
        </div>
      </div>

      <!-- 捐赠失败弹窗 -->
      <div class="jj_dialog succeed" v-if="data.errorDiaShow">
        <div class="succeed_view">
          <img class="succeed_img" src="@/assets/images/donate/error.png" alt="">
          <div style="color: #888;margin-bottom: .4rem;">支付遇到点问题，请重试~</div>
          <div>
            <div class="btn red_btn" @click="data.errorDiaShow = false">关闭</div>
          </div>
        </div>
      </div>


      <!-- //loading提示弹窗 -->
      <div class="jj_dialog" v-if="data.Loading">
        <van-loading color="#ffffff" vertical style="margin: 40vh 0;">加载中...</van-loading>
      </div>


      <!-- 捐赠提示弹窗 -->
      <div class="jj_dialog succeed" v-if="data.warnDiaShow">
        <div class="succeed_view">
          <img class="succeed_img" src="@/assets/images/donate/warn.png" alt="">
          <div style="color: #888;margin-bottom: .4rem;">请确认支付是否已完成</div>
          <div>
            <div class="btn red_btn" @click="findPayOrder">已完成支付</div>
            <div class="btn" @click="data.warnDiaShow = false">返回</div>
          </div>

        </div>
      </div>


      <!-- 微信捐赠捐赠提示弹窗 -->
      <div class="jj_dialog succeed" v-if="data.wechatPayWarnning" @click="data.wechatPayWarnning = false">
        <div class="succeed_view" @click.stop="">
          <img class="succeed_img" src="@/assets/images/donate/warn.png" alt="">
          <div style="color: #888;margin:.4rem;">请使用浏览器打开支付,</div>
          <div style="color: #888;margin:0 .4rem .6rem;">暂不支持微信内付款。</div>
          <div>
            <!-- <div class="btn red_btn" @click="data.wechatPayWarnning = false">关闭</div> -->
          </div>

        </div>
      </div>

    </div>




    <!-- 项目弹窗 -->
    <div class="jj_dialog" v-if="data.porjectDiaShow" @click="data.porjectDiaShow = false">
      <div class="dialog_box" @click.stop="">
        <div class="dialog_header space_between align_items_center">
          <div @click="data.porjectDiaShow = false">取消</div>
          <div class="f35 fw6">选择项目</div>
          <div class="cred fw6" @click="projectSelectedFn">确定</div>
        </div>
        <div class="list">
          <div v-for="(item, index) in data.projectList" :key="index"
            :class="data.projectSelectedMsg.id == item.id ? 'active' : ''" @click="projectSelectChange(item)">{{
              item.title }}</div>
        </div>
      </div>
    </div>

    <!-- 渠道弹窗 -->
    <!-- <div class="jj_dialog" v-if="data.channelDiashow">
      <div class="dialog_box">
        <div class="dialog_header space_between align_items_center">
          <div>取消</div>
          <div class="f35 fw6">选择渠道</div>
          <div class="cred fw6">确定</div>
        </div>
        <div class="list">
          <div v-for="(item, index) in channelList" :key="index" :class="index == 2 ? 'active' : ''">{{ item.name }}</div>
        </div>
      </div>
    </div> -->
    <Footer />
  </div>
</template>

<script setup>
import Footer from "../../components/Footer.vue";
import { reactive, onMounted } from "vue";
import Header from "../../components/Header.vue";
import Api from "../../utils/api";
import { Toast, Popup } from 'vant';
import { useRouter, useRoute } from 'vue-router'
import { Loading } from "vant";
import { openAppFunction } from '../../common/js/share'
const route = useRoute()
const router = useRouter()

let data = reactive({
  checked: true,
  current: 0,
  warnDiaShow: false,
  errorDiaShow: false,
  succeedDiaShow: false,
  wechatPayWarnning: false,
  titleName:'我要捐赠',
  postData: {
    // 金额
    amount: '',

    // 是否指定项目0否1是
    assignProject: 0,

    // 指定项目id
    project_id: '',

    //指定项目名称
    oname: '',

    //捐赠来源 1个人 2 单位
    type: 1,

    //捐赠人（联系人）姓名
    person: '',

    // 捐赠人邮箱
    email: '',

    //捐赠人手机号
    phone: '',

    //收据抬头
    invoice_title: '',

    //收据税号
    invoice_tax_id: '',

    // 渠道ID
    channel_id: '',

    //渠道名称
    channel_name: '',

    //是否需要收据：0不需要1需要
    receipt: 0,

    //单位名称
    briefl_name: '',

    //允许列入公式：0否1是
    openBel: true,
    open: 1,

    // 、、捐款平台:1手机h5，2电脑pc
    platform: 1,

    //发票邮箱
    invoice_email: '',

    //支付方式1微信 2支付宝
    payType: 1,
  },
  Loading: false,
  porjectDiaShow: false,
  channelDiashow: false,
  projectList: [],
  projectSelectedMsg: {
    id: '',
    title: '',
  },
  channelList: [],
  projId: '',
  outTradeNo: '',
  zsObj: {},
});

onMounted(() => {
  if (route.query.id) {
    data.projId = route.query.id
  }
  if (route.query.outTradeNo) {
    data.warnDiaShow = true
    data.outTradeNo = route.query.outTradeNo

  }




  //获取项目列表
  getwelfareGetlist()
  //获取渠道列表
  // Api.channelsGetlist().then((res) => {
  //   console.log(res);
  //   if(res.status == 200){
  //     data.channelList = res.data
  //   }
  // });


  shareFn()

});

const shareFn = () => {
  const jsonString = {
    shareUrl:window.location.href,//分享链接
    shareCorver: window.location.origin + '/logo.png',
    shareTitle: '中国职工发展基金会',
    shareDesc: data.titleName,//分享描述
  }
  console.log(jsonString)
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}

//查看证书详情
const toZSdeatil = () => {
  const item = data.zsObj;
  console.log(item)
  router.push({ path: '/certificate', query: { tmpName: item.tmpName, amount: item.amount, pay_time: item.pay_time, id: item.id } })
};


const findPayOrder = () => {
  data.warnDiaShow = false
  data.Loading = true
  Api.getDonateOrderDetail({ outTradeNo: data.outTradeNo }).then(res => {
    data.Loading = false
    if (res.status == 200) {
      if (res.data.pay_time > 0) {
        //data.zsObj.tmpName = res.data.briefl_name ? res.data.briefl_name : res.data.person ? res.data.person : '爱心人士'
		data.zsObj.tmpName = res.data.type == "2" ? ((res.data.briefl_name != '' && res.data.briefl_name != null) ? res.data.briefl_name : "爱心人士") : ((res.data.person ==''||res.data.person ==null)?'爱心人士':res.data.person);
        console.log(data.zsObj.tmpName)
        data.zsObj.amount = res.data.amount.toFixed(2)
        data.zsObj.pay_time = res.data.pay_time
		data.zsObj.id=res.data.id;
        data.succeedDiaShow = true
      } else {
        data.errorDiaShow = true

      }
    }
  })
}

//提交
const submit = () => {
  
  let parmas = {}
  for (const key in data.postData) {
    if (data.postData[key]) {
      parmas[key] = data.postData[key]
    }
  }

  //验证
  if (verify(data.postData)) {
    parmas.open = data.postData.openBel ? 1 : 0

    if (parmas.payType == 1) {
      var userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('micromessenger') !== -1) {
        data.wechatPayWarnning = true
        console.log('当前页面在微信中打开');
      } else {
        console.log('当前页面不在微信中打开');
        Api.wechatPay(parmas).then(res => {
          console.log(res)
          if (res.status == 200) {
            window.location.href = res.data.url
          }
        })

      }




    }
    if (parmas.payType == 2) {
      Api.alipayPay(parmas).then(res => {
        if (res.status == 200) {
          window.location.href = res.data.url
        }
      })
    }
  }


};

//验证
const verify = (obj) => {
  // 金额确认
  let amountReg = /^\d+(\.\d{1,2})?$/;
  if (!amountReg.test(obj.amount)) {
    Toast('请输入正确捐赠金额！');
    return
  }

  if (obj.assignProject == 1 && !obj.project_id) {
    Toast('请选择指定项目名称！');
    return
  }

  if (obj.phone) {
    // 手机号确认
    let phoneReg = /^1[3456789]\d{9}$/;
    if (!phoneReg.test(obj.phone)) {
      Toast('请输入正确手机号！');
      return
    }
  }
  if (obj.email) {
    const email_reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
    if (!email_reg.test(obj.email)) {
      Toast('请输入正确邮箱！');
      return
    }
  }
  if (obj.type == 2) {
    if (!obj.briefl_name) {
      Toast('请输入单位全称！');
      return
    }
    const briefl_name_reg =  /^[\u4e00-\u9fa5|（）|()]{2,20}$/;
    if (!briefl_name_reg.test(obj.briefl_name)) {
        Toast('请输入正确单位全称！');
        return
      }
    if (!obj.phone) {
      Toast('请输入联系人手机号！');
      return
    }
    if (!obj.person) {
      Toast('请输入联系人！');
      return
    }
  }
  if (data.postData.receipt == 1) {
    //发票抬头正则
    const invoice_title_reg = /^[0-9a-zA-Z\(\)\（\）\u4e00-\u9fa5]{0,50}$/

    //抬头名称去掉首尾空格
    obj.invoice_title = obj.invoice_title.trim()
    if (!obj.invoice_title) {
      Toast('请输入收据抬头！');
      return
    }
    
    if (obj.invoice_title.length < 2) {
      Toast('收据抬头至少2个字符!');
      return
    }
    const invoice_tax_id_reg = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/
    if(obj.type == 2&&!obj.invoice_tax_id){
      if (!invoice_tax_id_reg.test(obj.invoice_tax_id)) {
        Toast('请输入收据税号！');
        return
      }
    }
    if (obj.invoice_tax_id) {
      
      if (!invoice_tax_id_reg.test(obj.invoice_tax_id)) {
        Toast('请输入正确收据税号！');
        return
      }
    }
    
    const invoice_email_reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    if (!invoice_email_reg.test(obj.invoice_email)) {
      Toast('请输入正确收据邮箱！');
      return
    }
  }
  return true
};
// 捐赠类别-无定向捐款0 指定项目捐款1
const assignProjectChange = (index) => {
  console.log(index)
  data.postData.assignProject = index;
  data.postData.project_id = ''
  data.postData.oname = ''
  data.projectSelectedMsg.id = ''
  data.projectSelectedMsg.title = ''
};

//捐款来源 - 个人1 单位2
const typeChange = (index) => {
  console.log(index)
  data.postData.type = index;
  const labekName = ['person', 'email', 'phone', 'briefl_name']
  for (let i = 0; i < labekName.length; i++) {
    data.postData[labekName[i]] = ''
  }

};

//获取捐赠项目列表
const getwelfareGetlist = () => {
  Api.welfareGetlist({ top: 0 }).then((res) => {
    if (res.status == 200) {
      data.projectList = res.data
      if (data.projId) {
        for (let i = 0; i < data.projectList.length; i++) {
          if (data.projectList[i].id == data.projId) {
            data.postData.project_id = data.projId
            data.postData.oname = data.projectList[i].title
            data.postData.assignProject = 1
          }
        }
      }
      console.log(data.projectList)
    }
  });
};


// 项目列表点击选中
const projectSelectChange = (item) => {
  console.log(8888)
  data.projectSelectedMsg.id = item.id;
  data.projectSelectedMsg.title = item.title
};

// 项目列表弹窗点击确定
const projectSelectedFn = () => {
  data.postData.project_id = data.projectSelectedMsg.id
  data.postData.oname = data.projectSelectedMsg.title
  data.porjectDiaShow = false;
};

// 项目列表谈弹窗显示
const porjectDiaShow = () => {
  data.projectSelectedMsg.id = data.postData.project_id
  data.projectSelectedMsg.title = data.postData.oname
  data.porjectDiaShow = true;
};

// 捐赠金额点击选中设置金额
const amountBtnChange = (num) => {
  data.postData.amount = num;
};

// 是否需要收据 - 0不需要 1需要
const receiptChange = (index) => {
  data.postData.receipt = index;
  const labekName = ['invoice_tax_id', 'invoice_title', 'invoice_email']
  for (let i = 0; i < labekName.length; i++) {
    data.postData[labekName[i]] = ''
  }
};

// 跳转积分捐赠
const toIntegral = () => {
  window.location.href = 'https://jf.ccb.com/views/mobile/workerHome/index.html?orderType=92'
};
// 支付方式选择 - 1微信 2支付宝
const payTypeChange = (t) => {
  data.postData.payType = t
};

</script>

<style lang='less' scoped>
::v-deep input.van-field__control::-webkit-input-placeholder {
  color: #ACACAC;
  font-size: .3867rem;
}

.dialog_box {
  border-radius: .5067rem .5067rem 0px 0px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 4rem;
  background-color: #fff;
  padding-bottom: .5rem;

  .list {
    max-height: 9.3333rem;
    overflow-y: scroll;

    >div {
      height: 1.3067rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .3867rem;
      border-top: .0133rem #E7E7E8 solid;
    }

    .active {
      background-color: #F5F5F5;
    }
  }

  .dialog_header {
    padding: 0 1.0933rem;
    font-size: .3867rem;
    height: 1.7333rem;

    .f35 {
      font-size: .4667rem;
    }
  }
}

.donate {
  display: flex;
  flex-flow: column;
  height: 100%;

  .content {
    flex: 1;
    overflow-y: auto;
    background-color: #fff;

    .succeed {
      .succeed_view {
        width: 6.92rem;
        padding: .6667rem 0;
        background: #FFFFFF;
        border-radius: .2533rem;
        position: absolute;
        left: 0;
        right: 0;
        top: 30vh;
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: .3867rem;
        min-height: 6.4rem;

        .btn {
          width: 5.64rem;
          height: 1.1333rem;
          color: #333;
          background-color: #F5F5F5;
          border-radius: .1067rem;

          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: .2667rem;
        }

        .red_btn {
          background: #E61D18 !important;
          color: #fff !important;
        }

        .succeed_img {
          width: 1.4667rem;
          margin-bottom: .4rem;

        }
      }
    }

    .van-cell {
      padding-right: 0;
    }

    .donate_sum {
      >div {
        min-height: 1.2533rem;
      }
    }

    .donate_msg,
    .blank_msg {
      background-color: #fff;
      padding: 0 .2933rem;
      padding-bottom: .5067rem;
    }

    .num_btns {
      >div {
        width: 2.2533rem;
        height: 1.3333rem;
        background-color: #FFF4F3;
        color: #E61D18;
        border: .0133rem solid rgba(255, 255, 255, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .3133rem;
        font-weight: 600;
        margin-bottom: .32rem;
        border-radius: .0533rem;

        span {
          margin-bottom: .0667rem;
          margin-right: .0667rem;
        }
      }

      .active {
        border: .0133rem solid #E61D18;
      }
    }

    .c8 {
      color: #888;
    }

    .f21 {
      font-size: .28rem;
    }

    .right {
      width: .16rem;
      margin-left: .2rem;
    }

    .notice {
      width: .4133rem;
      margin-right: .16rem;
    }

    .blank_msg {
      padding-top: .2rem;
    }

    .submitBtn {
      width: 9.3867rem;
      height: 1.1333rem;
      background: #E61D18;
      border-radius: .1067rem;
      margin: .4rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: .4rem;
    }

    .items {
      border-bottom: .0133rem solid #ECECEC;
      min-height: 1.2533rem;
      font-size: .3867rem;

      .zk {
        width: .5rem;
      }

      .bt {
        width: .8267rem;
        height: .4133rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .28rem;
        color: #E61D18;
        background-color: #FFF4F3;
        font-weight: 400;
        margin-left: .16rem;
      }

      .btns {
        display: flex;

        >div {
          width: 2.56rem;
          height: .72rem;
          background-color: #f5f5f5;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: .16rem;
          border-radius: .0533rem;
        }

        img {
          width: .4667rem;
          margin-right: .1067rem;
        }

        .active {
          background-color: #E61D18;
          color: #fff;
        }
      }

      >div {
        &:first-child {
          font-weight: 600;
          display: flex;
          align-items: center;
          white-space: nowrap;
        }
      }
    }

    .classname {

      color: #333;
      font-size: .44rem;
      font-weight: 600;

      >div {
        width: 1.8467rem;
      }

      .line {
        background: linear-gradient(240deg, rgba(255, 255, 255, 0) 0%, #E61D18 100%);
        margin-top: .1067rem;
        height: .1067rem;
        border-radius: 1.28rem;
        // margin-bottom: .2667rem;
      }

    }
  }
}
</style>